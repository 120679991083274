import React, { useState } from "react"
import AccordionItem from "../../atoms/accordion-item/AccordionItem"
import { AccordionData } from "./AccordionData"
// import "./Accordion.css"

export default function Accordion() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  return (
    <div className="bf-faq-wrapper" id="help">
      {AccordionData.map((faq, index) => (
        <AccordionItem
          key={index}
          question={faq.question}
          answer={faq.answer}
          isActive={activeIndex === index}
          onClick={() => setActiveIndex(activeIndex === index ? null : index)}
        />
      ))}
    </div>
  )
}
