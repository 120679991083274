import { useState, useEffect, useRef } from "react"
import { useLocation, Link, useNavigate } from "react-router-dom"
import accountIcon from "./../../../../assets/img/user.svg"
import Icon from "../../../../assets/img/arrow-1.svg"

interface MobileNavProps {
  setCloseSubMenu: (value: boolean) => void
  closeSubMenu: boolean
  setShowSubMenuUser: (value: boolean) => void
  showSubMenuUser: boolean
}

const MobileNav = (mobileNavProps: MobileNavProps) => {
  const ref = useRef<HTMLUListElement | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const [showSubMenu, setShowSubMenu] = useState(false)
  const status = localStorage.getItem("status")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hidden, setHidden] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
    if (mobileNavProps.showSubMenuUser === true) {
      mobileNavProps.setShowSubMenuUser(false)
    }
  }
  const [active, setActive] = useState("")

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (mobileNavProps.closeSubMenu) {
      setShowSubMenu(false)
      mobileNavProps.setCloseSubMenu(false)
    }
  }, [mobileNavProps.closeSubMenu])

  useEffect(() => {
    if (mobileNavProps.showSubMenuUser === true && menuOpen === true) {
      setMenuOpen(false)
    }
  }, [mobileNavProps.showSubMenuUser])

  function handleClick() {
    setShowSubMenu(!showSubMenu)
  }

  const handleLinkClick = () => {
    setMenuOpen(false)
  }

  function handleClickProduct(routeToRedirect: string) {
    navigate(`/contacto`)
    setTimeout(() => {
      navigate(`/productos/${routeToRedirect}`)
    }, 1)
  }

  const handleClickFuera = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowSubMenu(false)
      setMenuOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickFuera)

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      document.removeEventListener("mousedown", handleClickFuera)
    }
  }, [])

  useEffect(() => {
    switch (location.pathname) {
      case "/faq":
        setActive("faq")
        break
      case "/equipo":
        setActive("team")
        break
      case "/blog":
        setActive("blog")
        break
      case "/contacto":
        setActive("contact")
        break
      case "/":
        setActive("home")
        break
      case "/iniciar-sesion":
        setActive("")
        break
      case "/register":
        setActive("")
        break
      case "/productos/dieta":
        setActive("#")
        setHidden(false)
        break
      case "/productos/psicologia":
        setActive("#")
        setHidden(false)
        break
      case "/productos/talleres":
        setActive("#")
        setHidden(false)
        break
    }
  }, [location])

  const isColored = location.pathname.includes("/geopol")

  return (
    <div className={`mobile-nav ${isColored ? "colored-mobile-menu" : ""}`}>
      <button className="menu-toggle" onClick={toggleMenu}>
        <span className="menu-icon">
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </span>
      </button>
      {menuOpen && (
        <ul className="mobile-ul">
          <li className="li">
            <Link
              to="/"
              className={`a ${active === "home" && "active"}`}
              id="home"
              onClick={() => {
                setShowSubMenu(false)
                handleLinkClick()
              }}
            >
              HOME
            </Link>
          </li>
          <li className="li">
            <Link
              to="#"
              className={`a ${active === "#" && "active"}`}
              id="products"
              onClick={() => {
                handleClick()
              }}
            >
              PRODUCTOS
              <img
                alt="icon"
                className="icon-collapse"
                src={Icon}
                style={{
                  transform: showSubMenu ? "rotate(180deg)" : undefined,
                  marginBottom: "4px"
                }}
              />
            </Link>

            {showSubMenu && (
              <ul className="submenu-mobile" ref={ref}>
                <li key={"diet"}>
                  <a
                    className="a"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClick()
                      handleClickProduct("dieta")
                      setShowSubMenu(false)
                      handleLinkClick()
                    }}
                  >
                    Dieta
                  </a>
                  <div className="divider-submenu"></div>
                </li>
                <li key={"psychology"}>
                  <a
                    className="a"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleClick()
                      handleClickProduct("psicologia")
                      setShowSubMenu(false)
                      handleLinkClick()
                    }}
                  >
                    Psicología
                  </a>
                  <div className="divider-submenu"></div>
                </li>
                <li key={"workshop"}>
                  <Link
                    className="a"
                    to={`/productos/talleres`}
                    onClick={() => {
                      handleClick()
                      handleClickProduct("talleres")
                      setShowSubMenu(false)
                      handleLinkClick()
                    }}
                  >
                    Talleres
                  </Link>
                </li>
              </ul>
            )}
          </li>
          <li className="li">
            <Link
              to="/faq"
              className={`a ${active === "faq" && "active"}`}
              id="faq"
              onClick={() => {
                setShowSubMenu(false)
                handleLinkClick()
              }}
            >
              FAQ
            </Link>
          </li>
          <li className="li">
            <Link
              to="/equipo"
              className={`a ${active === "team" && "active"}`}
              id="team"
              onClick={() => {
                setShowSubMenu(false)
                handleLinkClick()
              }}
            >
              EQUIPO
            </Link>
          </li>
          <li className="li">
            <Link
              to="https://www.vikika.es/blog/"
              target="_blank"
              className={`a ${active === "blog" && "active"}`}
              id="blog"
              onClick={() => {
                setShowSubMenu(false)
                handleLinkClick()
              }}
            >
              BLOG
            </Link>
          </li>
          <li className="li">
            <Link
              to="/contacto"
              className={`a ${active === "contact" && "active"}`}
              id="contact"
              onClick={() => {
                setShowSubMenu(false)
                handleLinkClick()
              }}
            >
              CONTACTO
            </Link>
          </li>
          {status !== "isLoggedIn" && (
            <li>
              <Link
                to="/iniciar-sesion"
                className={`a ${active === "login" && "active"}`}
                id="login"
                onClick={() => {
                  setShowSubMenu(false)
                  handleLinkClick()
                }}
              >
                <img src={accountIcon} className="accountIcon" alt="Cuenta" /> INICIAR SESIÓN
              </Link>
            </li>
          )}
        </ul>
      )}
    </div>
  )
}

export default MobileNav
