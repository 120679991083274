import { useState, useEffect } from "react"
import PsychologySubscriptionCheckbox from "../checkbox/PsychologySubscriptionCheckbox"
import PsychologySessionsCheckbox from "../checkbox/PsychologySessionsCheckbox"
import Button from "../../../atoms/button/Button"
import RadioOption from "../radio/RadioOption"

import isBlackFriday from "../../../../../views/blackfriday/utils/isBlackFriday"
import { ProductDetailData, RadioCheckBoxOption } from "../../../../../../models/ProductDetailData"

import "./Promo.css"
import infoIconUrl from "../../../../../../assets/img/info-circle.png"
import { useNavigate } from "react-router-dom"

interface PromoProps {
  products: ProductDetailData[]
  inCouple: boolean
}

export default function PsychologyPromo({ products, inCouple }: PromoProps) {
  const navigate = useNavigate()

  const subscriptionProducts = products[1]
  const sessionProducts = products[0]

  /**
   * Variables needed for cart
   */
  const [productCategory, setProductCategory] = useState<String>("psychology")
  const [savedRadioProduct, setSavedRadioProduct] = useState<RadioCheckBoxOption>()
  const [productPrice, setProductPrice] = useState<number>(
    subscriptionProducts.radio_options[0].new_price_reduced_lonelly
  )

  const [selectedProductId, setSelectedProductId] = useState<string>(subscriptionProducts.idBasic)
  const [selectedVariantId, setSelectedVariantId] = useState<string>(
    subscriptionProducts.radio_options[0].idLonelly
  )

  /**
   * Variables not needed for cart
   */

  const [isSubscriptionChecked, setIsSubscriptionChecked] = useState(!inCouple)
  const [isSessionChecked, setIsSessionChecked] = useState(inCouple)
  const [selectedRadioOption, setSelectedRadioOption] = useState<string | null>(
    subscriptionProducts.radio_options[0].value
  )
  const [price, setPrice] = useState<any>(
    subscriptionProducts.radio_options[0].new_price_reduced_lonelly
  )
  const [prevPrice, setPrevPrice] = useState<number>(productPrice)
  const [showPromoButton] = useState<boolean>(isBlackFriday(new Date()))

  /**
   * Used to update productId and prices when couple toggle changes
   */
  useEffect(() => {
    setProductPrice(
      !inCouple
        ? subscriptionProducts.radio_options[0].new_price_reduced_lonelly
        : sessionProducts.radio_options[0].new_price_reduced_composed
    )
    setSelectedProductId(!inCouple ? subscriptionProducts.idBasic : sessionProducts.idCouple)
    setSelectedVariantId(
      !inCouple
        ? subscriptionProducts.radio_options[0].idLonelly
        : sessionProducts.radio_options[0].idComposed
    )
    setIsSessionChecked(!inCouple ? false : true)
    setIsSubscriptionChecked(!inCouple ? true : false)
    setSelectedRadioOption(
      !inCouple
        ? subscriptionProducts.radio_options[0].value
        : sessionProducts.radio_options[0].value
    )
  }, [inCouple])

  /**
   * Used to update the visual previous price
   */
  useEffect(() => {
    setPrevPrice(productPrice)
  }, [productPrice])

  /**
   * Used to update the visual current price
   */
  useEffect(() => {
    setPrice(prevPrice - prevPrice * 0.1)
  }, [prevPrice])

  const handleSubscriptionCheckChange = (checked: boolean) => {
    setIsSubscriptionChecked(checked)
    const firstOption = subscriptionProducts.radio_options[0].value
    if (checked) {
      setIsSessionChecked(false)
      setSelectedRadioOption(firstOption)
      setProductPrice(
        !inCouple
          ? subscriptionProducts.radio_options[0].new_price_reduced_lonelly
          : subscriptionProducts.radio_options[0].new_price_reduced_composed
      )
      setSelectedProductId(subscriptionProducts.idBasic)
      setSelectedVariantId(subscriptionProducts.radio_options[0].idLonelly)
    }
  }

  const handleSessionCheckChange = (checked: boolean) => {
    setIsSessionChecked(checked)
    const firstOption = sessionProducts.radio_options[0].value
    if (checked) {
      setIsSubscriptionChecked(false)
      setSelectedRadioOption(firstOption)
      setProductPrice(
        !inCouple
          ? sessionProducts.radio_options[0].new_price_reduced_lonelly
          : sessionProducts.radio_options[0].new_price_reduced_composed
      )
      setSelectedProductId(!inCouple ? sessionProducts.idBasic : sessionProducts.idCouple)
      setSelectedVariantId(
        !inCouple
          ? sessionProducts.radio_options[0].idLonelly
          : sessionProducts.radio_options[0].idComposed
      )
    }
  }

  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: RadioCheckBoxOption
  ) => {
    setSelectedRadioOption(event.target.value)
    setSavedRadioProduct(option)
    setProductPrice(
      !inCouple ? option.new_price_reduced_lonelly : option.new_price_reduced_composed
    )
    setSelectedVariantId(!inCouple ? option.idLonelly : option.idComposed)
  }

  const handleButtonClick = (product: any) => {
    localStorage.removeItem("payment_intent")
    localStorage.removeItem("client_secret")
    localStorage.removeItem("facturationData")
    localStorage.setItem(
      "savedSelectedProduct",
      JSON.stringify({
        value: savedRadioProduct,
        productId: selectedProductId,
        variantId: selectedVariantId,
        productPrice: productPrice,
        name: product.name,
        img: product.img,
        alt: product.alt,
        productCategory: productCategory,
        isEnPareja: inCouple,
        productSubscription: product.product_subscription,
        productSubscriptionPrice: 0
      })
    )
    const discount = "CM24-10"
    navigate(`/pago?coupon=${discount}`)
  }

  const getRadioOptions = (options: any) => {
    return options.map((option: any) => (
      <RadioOption
        key={option.value}
        id={`psychology-promo-${option.value}`}
        label={option.option_name}
        name={`psychology-couple-${inCouple.toString().toLowerCase()}`}
        value={option.value}
        checked={selectedRadioOption === option.value}
        onChange={event => handleRadioChange(event, option)}
      />
    ))
  }

  return (
    <div className="bf-promo-wrapper">
      <div className="bf-promo-price-container">
        <div className="bf-promo-price-wrapper">
          <div className="bf-promo-price">
            <h2 className="bf-promo-price-number">
              {price % 1 === 0
                ? price
                : price.toLocaleString("es-ES", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
            </h2>
            <div>
              <h3 className="bf-promo-price-promo">PROMO</h3>
              <p className="bf-promo-price-simbol">€{isSubscriptionChecked && <span>/mes</span>}</p>
            </div>
          </div>
          <p className="bf-promo-prev-price">
            Antes:{" "}
            {prevPrice % 1 === 0
              ? prevPrice
              : prevPrice.toLocaleString("es-ES", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
            €
          </p>
        </div>
      </div>
      <div className="bf-promo-info-wrapper">
        <p className="bf-promo-info-text">
          Nos adaptamos a lo que necesites. Elige entre nuestras suscripciones mensuales o nuestra
          sesión individual y bonos de forma individual o en pareja.
        </p>
        {!inCouple && (
          <>
            <div className="bf-promo-plan-selector">
              {/* Mostrar checkbox de suscripción */}
              <PsychologySubscriptionCheckbox
                label="Elige tu Suscripción"
                checked={isSubscriptionChecked}
                onChange={handleSubscriptionCheckChange}
              />
              {isSubscriptionChecked && (
                <div className="bf-promo-radio-wrapper">
                  {/* Mostrar opciones de radio para suscripción */}
                  {getRadioOptions(subscriptionProducts.radio_options)}
                </div>
              )}
            </div>
            <div className="bf-promo-divider-wrapper">
              <p className="bf-promo-divider">o si lo prefieres</p>
            </div>
          </>
        )}
        {/* Mostrar checkbox de sesiones */}
        <div className="bf-promo-plan-selector">
          <PsychologySessionsCheckbox
            label="Elige Sesiones por bonos"
            checked={isSessionChecked}
            onChange={handleSessionCheckChange}
          />
          {isSessionChecked && (
            <div className="bf-promo-radio-wrapper">
              {getRadioOptions(sessionProducts.radio_options)}
            </div>
          )}
        </div>
        <div className="bf-promo-info-extra">
          <img src={infoIconUrl} alt="info" className="bf-promo-info-icon" />
          <p className="bf-promo-info-anchor">
            Si tienes dudas de cómo funciona te lo explicamos{" "}
            <a href="https://vikika.es/productos/psicologia" target="_blank">
              aquí
            </a>
          </p>
        </div>
      </div>
      {showPromoButton && (
        <div>
          <Button
            ctaText="COMPRAR"
            disabled={!isSubscriptionChecked && !isSessionChecked}
            onClick={() =>
              handleButtonClick(isSubscriptionChecked ? subscriptionProducts : sessionProducts)
            }
          />
        </div>
      )}
    </div>
  )
}
