export const geopolFaqsData = [
  {
    question: "¿Qué es VIKIKA TEAM?",
    answer:
      "VIKIKA TEAM está formado por un equipo de especialistas en nutrición, entrenamiento y psicología, que te ayudan a alcanzar o mejorar tus hábitos a través de planes adaptados a tus objetivos."
  },
  {
    question: "¿La guía indica un menú de comidas personalizado?",
    answer:
      '<p>No, la guía de educación nutricional no especifica qué comidas, alimentos y cantidades debes realizar en tu día a día. Para ello, será necesario realizar un <a href="https://vikika.es/productos/dieta" target="_blank" rel="nonreferer">plan personalizado.</a><br>La guía de educación nutricional te ayudará, a través de información respaldada por nuestro equipo de nutricionistas, a definir la frecuencia de consumo de alimentos; a organizar tus comidas en función de tus objetivos; a mejorar la calidad de tus platos mediante una óptima elección de alimentos, calidad y cocinado de los mismos… para, de este modo, mejorar tus hábitos de alimentación.</p>'
  },
  {
    question: "¿Tiene la guía alguna duración?",
    answer:
      "La guía está pensada para que puedas seguirla durante el proceso de oposición en el que te encuentras, hasta la fecha de tus pruebas físicas."
  },
  {
    question: "¿Puedo renovar la guía?",
    answer:
      "Si has estado siguiendo la guía hasta tus pruebas físicas y necesitas renovarla, porque ha transcurrido un tiempo y tus objetivos han cambiado, podrás hacerlo."
  },
  {
    question: "¿Puedo contactar con mi nutricionista después de la videollamada?",
    answer:
      '<p>No, la guía está diseñada para que puedas realizarla sin necesidad de tener un seguimiento con el nutricionista. En la videollamada, el nutricionista te dará los detalles necesarios o aclaraciones sobre el documento para que puedas seguirlo sin inconvenientes. Si sientes que necesitas un mayor seguimiento, sería aconsejable realizar un <a href="https://vikika.es/productos/dieta" target="_blank" rel="nonreferer">plan personalizado.</a></p>'
  },
  {
    question:
      "Si tengo alergias, intolerancias o problemas digestivos, ¿es recomendable esta guía?",
    answer:
      'Sí, no habría inconveniente en tener tu guía de educación nutricional. No obstante, deberás tener en cuenta que si lo que buscas es una planificación personalizada según tus patologías, sería necesario realizar un <a href="https://vikika.es/productos/dieta" target="_blank" rel="nonreferer">plan personalizado.</a>'
  },
  {
    question: "Si tengo dudas sobre la guía, ¿dónde las consulto?",
    answer:
      "El nutricionista te explicará todo lo necesario en la videollamada para que puedas seguir la guía sin complicaciones. Si tras la explicación del nutricionista te queda alguna duda, podrás preguntársela en esa misma videollamada."
  },
  {
    question: "Si tengo dudas sobre el producto antes de comprarlo, ¿dónde puedo consultarlas?",
    answer:
      "Ante cualquier duda antes de comprar el plan o durante el proceso, hasta que realizas la videollamada con el nutricionista, puedes preguntarnos desde nuestro chat en la web o escribiendo a <a href='mailto:attcliente@vikika.es'>attcliente@vikika.es</a>"
  },
  {
    question: "¿Puedo cancelar la compra de la guía?",
    answer:
      "En el momento que tengas asignado a tu especialista, ya no podrás realizar la cancelación del producto."
  }
]
