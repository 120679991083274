/* eslint-disable jsx-a11y/alt-text */
import { useState, useRef, RefObject } from "react"
import {
  renounceVideoCallService,
  updateVideoCallService
} from "../../../../../services/videoCallService"
import "./calendar.css"
import * as React from "react"
import Drawer from "@mui/material/Drawer"
import "dayjs/locale/es"
import dayjs from "dayjs"
import isBetweenPlugin from "dayjs/plugin/isBetween"
import ModalCites from "./modalCites"
import Cites from "./cites"
import useDietCalendarQuery from "../../../../../hooks/useDietCalendarQuery"
import CalendarHoursArea from "./calendar_hours_area"
import { IAppointmentSlot } from "../../../../../services/getDietCalendarService"
import CalendarVideocallArea from "./calendar_videocall_area"
import CalendarCalendarArea from "./calendar_calendar_area"
import ModalRedirect from "./modal_redirect"
import PrivateLoading from "../../../../shared/loading/PrivateLoading"

interface DrawerContentProps {
  drawerView: string
  noShowButtons: boolean
}

function touchWeekDays() {
  const elements = document.querySelectorAll(".MuiDayCalendar-weekDayLabel")
  if (elements.length > 0) {
    // OJO: en mi navegador la columna 0 es LUNES, no DOMINGO
    elements[0].textContent = "LU"
    elements[1].textContent = "MA"
    elements[2].textContent = "MI"
    elements[3].textContent = "JU"
    elements[4].textContent = "VI"
    elements[5].textContent = "SA"
    elements[6].textContent = "DO"
  }
}

dayjs.extend(isBetweenPlugin)

interface CalendarProps {
  tab: string
  handleOptionClick: (option: string) => void
  miComponenteRef: RefObject<HTMLDivElement>
}

const Calendar = (calendarProps: CalendarProps) => {
  const [typePlan, setTypePlan] = useState<string>("diet")
  const [showModalRedirect, setShowModalRedirect] = useState(false)
  // Aquí almacenamos la fecha elegida del calendario, aunque aún no sepamos la hora, formato Y-m-d
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  // Aquí almacenamos fecha y hora elegidas, con inicio y fin, etc.
  const [selectedSlot, setSelectedSlot] = useState<IAppointmentSlot | null>(null)
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [selectedTab, setSelectedTab] = useState<"schedule" | "dates">("schedule")
  const [drawerView, setDrawerView] = useState<"schedule" | "details">("schedule")
  const [dateConfirmed, setDateConfirmed] = useState(false)
  const [selectedCitePrevious, setSelectedCitePrevious] = useState<boolean>(false)
  const { data, error, isLoading, refetch, isRefetching } = useDietCalendarQuery()

  const noShowButtons = false

  const calendarioRef = useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    calendarioRef.current?.scrollIntoView({ block: "start", behavior: "smooth" })
    const timer = setTimeout(() => {
      window.scrollTo(0, 0)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  React.useEffect(() => {
    touchWeekDays()
  }, [])

  React.useEffect(() => {
    // Verificar si 'tab' es igual a 'videocall' y establecer 'schedule' si es así
    if (calendarProps.tab === "videocall") {
      setSelectedTab("dates")
    }
  }, [calendarProps.tab]) // Ejecutar el efecto cuando 'tab' cambie

  if (isLoading) {
    return (
      <>
        <h1 className="title_calendar">CALENDARIO Y GESTIÓN DE VIDEOLLAMADA</h1>
        <p className="subtitle_calendar">
          Estamos comprobando si tienes citas pendientes o si puedes agendar una nueva cita.
        </p>
        <PrivateLoading />
      </>
    )
  } else if (error) {
    return (
      <>
        <h1 className="title_calendar">CALENDARIO Y GESTIÓN DE VIDEOLLAMADA</h1>
        <p className="subtitle_calendar">
          Actualmente no tienes disponible el calendario para agendar videollamada.
        </p>
      </>
    )
  } else if (!data) {
    return (
      <>
        <h1 className="title_calendar">CALENDARIO Y GESTIÓN DE VIDEOLLAMADA</h1>
        <p className="subtitle_calendar">Error de comunicaciones.</p>
      </>
    )
  }

  // Filtrar last_reply.citas a fecha. data.citas.start has a string representation, and we want unique Y-m-d dates
  const highlightedDays = data.citas
    .map(cita => {
      return cita.start.format("YYYY-MM-DD")
    })
    .filter((value, index, self) => {
      return self.indexOf(value) === index
    })

  const handleCancelSubscription = () => {
    setShowModalRedirect(true)
  }

  const cancelSubscription = async () => {
    const response = await renounceVideoCallService()
    if (response.data === 1) {
      setShowModalRedirect(false)
      setSelectedCitePrevious(true)
    }
    calendarProps.handleOptionClick("Home")
    calendarProps.miComponenteRef.current?.scrollIntoView({ block: "start", behavior: "smooth" })
  }

  const onVideoCallNoAgendar = () => {
    setDrawerView("schedule")
    setSelectedDate(null)
    setSelectedSlot(null)
    setDrawerOpen(false)
    setShowModalRedirect(true)
  }

  const after_save = () => {
    setDateConfirmed(false)
    setDrawerView("schedule")
    setDrawerOpen(false)
    setSelectedTab("dates")
  }

  const not_save = () => {
    setSelectedDate(null)
    setSelectedSlot(null)
    setDrawerView("schedule")
    setDrawerOpen(false)
    setSelectedTab("schedule")
  }

  // El usuario quiere agendar una cita de videoconsulta, o actualizar una previa, según tengamos data.citaPendiente o no
  const onVideoCallAgendarClicked = async () => {
    if (!selectedDate || !selectedSlot) {
      console.warn("Error de lógica")
      return null
    }

    try {
      const fecha_inicio = selectedDate + " " + selectedSlot.start.format("HH:mm:00")
      const cita2 = data.citas.find(
        item => item.start.format("YYYY-MM-DD HH:mm:00") === fecha_inicio
      )
      if (cita2 !== undefined) {
        const response = await updateVideoCallService({
          IdVideoCallUpdated: cita2.id
        })
        if (response.data["inscripcion_realizada"]) {
          refetch()
          after_save()
        } else {
          not_save()
        }
      }
    } catch (e) {
      window.alert("Error registrando la cita")
    }
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return
    }
    setDrawerOpen(open)
  }

  // El calendario nos proporciona una fecha, pero no una hora aún.
  const onSelectDate = (ymd: string | null) => {
    if (ymd !== null && highlightedDays.includes(ymd)) {
      setSelectedDate(ymd)
      setSelectedSlot(null)
      setDrawerView("schedule")
      setDrawerOpen(true)
    } else {
      setSelectedDate(null)
      setSelectedSlot(null)
      setDrawerOpen(false)
    }
  }

  // La barra derecha nos proporciona un slot, con su fecha/hora de inicio y de fin
  const onSelectSlot = (slot: IAppointmentSlot) => {
    setSelectedSlot(slot)
    setDrawerView("details")
  }

  // El usuario quiere esconder la zona de la derecha, sea cual sea
  const onHideDrawer = () => {
    toggleDrawer(false)
    setSelectedDate(null)
    setSelectedSlot(null)
    setDrawerOpen(false)
  }

  const drawerContent = ({ drawerView, noShowButtons }: DrawerContentProps) => {
    if (drawerView === "schedule" && selectedDate) {
      return (
        <CalendarHoursArea
          onHideDrawer={onHideDrawer}
          selectedDate={selectedDate}
          onSelectAppointmentSlot={onSelectSlot}
          options={data.citas.filter(cita => {
            return cita.start.format("YYYY-MM-DD") === selectedDate
          })}
        />
      )
    } else if (drawerView === "details" && data.empleado && selectedSlot) {
      return (
        <CalendarVideocallArea
          type="diet"
          onHideDrawer={onHideDrawer}
          specialist={data.empleado}
          selected_slot={selectedSlot}
          booked_slot={data.citaPendiente}
          onVideoCallNoAgendar={onVideoCallNoAgendar}
          onVideoCallAgendarClicked={onVideoCallAgendarClicked}
          noShowButtons={noShowButtons}
        />
      )
    }

    return null
  }

  return (
    <div className="container_calendar_general" ref={calendarioRef}>
      <React.Fragment key={"right"}>
        <Drawer anchor={"right"} open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerContent({ drawerView, noShowButtons })}
        </Drawer>
      </React.Fragment>

      <h1 className="title_calendar">CALENDARIO Y GESTIÓN DE VIDEOLLAMADA</h1>
      <p className="subtitle_calendar">
        Selecciona e inscríbete en una de las citas disponibles para realizar la videollamada con tu
        especialista. Posteriormente, recibirás tu plan personalizado.
      </p>
      <div className={"order-history calendar-content"}>
        <div className={"order-history-options calendar-options"}>
          <p
            className={
              selectedTab === "schedule" ? "text-monserrat active-option" : "text-monserrat "
            }
            onClick={() => setSelectedTab("schedule")}
          >
            Agendar
          </p>
          <p
            className={selectedTab === "dates" ? "text-monserrat active-option" : "text-monserrat "}
            onClick={() => {
              setSelectedTab("dates")
            }}
          >
            Tus citas
          </p>
        </div>

        {selectedTab === "schedule" && (
          <>
            {(!selectedSlot || !dateConfirmed) &&
            selectedCitePrevious === false &&
            !data.noVisible ? (
              <CalendarCalendarArea
                calendarType={"diet"}
                highlightedDays={highlightedDays}
                onSelectDate={onSelectDate}
                selectedDate={selectedDate}
              />
            ) : (
              <ModalCites />
            )}
          </>
        )}

        {selectedTab === "dates" && selectedSlot && dateConfirmed && !data.citaPendiente && (
          <>
            {isRefetching && (
              <div className="calendar_dates_loading">
                <PrivateLoading />
              </div>
            )}
            <Cites
              start_dayjs={selectedSlot.start}
              end_dayjs={selectedSlot.end}
              instruc=""
              plan="Plan de dieta"
              type_calendar={typePlan}
              specialist={data.empleado.first_name}
              fullText={true}
              couple={false}
            />
          </>
        )}

        {selectedTab === "dates" && data.citaPendiente && (
          <>
            {isRefetching && (
              <div className="calendar_dates_loading">
                <PrivateLoading />
              </div>
            )}
            <Cites
              start_dayjs={data.citaPendiente.start_dayjs}
              end_dayjs={data.citaPendiente.end_dayjs}
              instruc={data.citaPendiente.instruc}
              plan={data.citaPendiente.plan}
              type_calendar={typePlan}
              specialist={data.citaPendiente.first_name}
              fullText={true}
              couple={false}
            />
          </>
        )}

        {selectedTab === "schedule" &&
          (!selectedSlot || !dateConfirmed) &&
          selectedCitePrevious === false &&
          !data.noVisible && (
            <div className={"card_calendar_main"}>
              <div className={"calendar-card-content"}>
                <p className={"subscription-renovation"}>
                  Pulsa en el botón “no agendar” en caso de que prefieras recibir tu plan
                  directamente, sin comunicarte con tu especialista.
                </p>
              </div>
              <div className={"calendar-card-action"}>
                <button onClick={handleCancelSubscription}>No agendar</button>
              </div>
            </div>
          )}
      </div>

      {showModalRedirect && (
        <ModalRedirect
          onHide={() => setShowModalRedirect(false)}
          onCancelSubscription={cancelSubscription}
        />
      )}
    </div>
  )
}

export default Calendar
