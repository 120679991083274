import { useRef, useEffect } from "react"
import parse from "html-react-parser"

interface AccordionItemProps {
  question: string
  answer: string
  isActive: boolean
  onClick: () => void
}

export default function AccordionItem({ question, answer, isActive, onClick }: AccordionItemProps) {
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isActive ? `${contentRef.current.scrollHeight}px` : "0px"
    }
  }, [isActive])

  return (
    <div className="bf-faq-item">
      <div className="bf-faq-question" onClick={onClick} aria-expanded={isActive}>
        {question}
        <svg
          viewBox="0 0 46 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`arrow-icon ${isActive ? "rotate" : ""}`}
        >
          <g opacity="0.5">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.687 23.4999C10.6871 23.2349 10.7925 22.9808 10.9799 22.7935C11.1673 22.6061 11.4214 22.5007 11.6864 22.5006L22.0007 22.5006L22.0007 12.1862C21.9959 12.052 22.0183 11.9182 22.0664 11.7929C22.1145 11.6675 22.1873 11.5531 22.2806 11.4564C22.3739 11.3598 22.4856 11.283 22.6092 11.2305C22.7329 11.178 22.8658 11.151 23.0001 11.151C23.1344 11.151 23.2673 11.178 23.3909 11.2305C23.5145 11.283 23.6263 11.3598 23.7195 11.4564C23.8128 11.5531 23.8857 11.6675 23.9337 11.7929C23.9818 11.9182 24.0042 12.052 23.9994 12.1862L23.9994 22.5006L34.3138 22.5006C34.448 22.4958 34.5818 22.5182 34.7072 22.5663C34.8325 22.6144 34.947 22.6872 35.0436 22.7805C35.1402 22.8737 35.217 22.9855 35.2695 23.1091C35.322 23.2327 35.349 23.3657 35.349 23.4999C35.349 23.6342 35.322 23.7672 35.2695 23.8908C35.217 24.0144 35.1402 24.1261 35.0436 24.2194C34.947 24.3127 34.8325 24.3855 34.7072 24.4336C34.5818 24.4817 34.448 24.5041 34.3138 24.4993L23.9994 24.4993L23.9994 34.8137C23.9903 35.0726 23.881 35.3179 23.6946 35.4979C23.5082 35.6778 23.2592 35.7784 23.0001 35.7784C22.741 35.7784 22.492 35.6778 22.3055 35.4979C22.1191 35.3179 22.0098 35.0726 22.0007 34.8137L22.0007 24.4993L11.6864 24.4993C11.4214 24.4992 11.1673 24.3938 10.9799 24.2064C10.7925 24.0191 10.6871 23.765 10.687 23.4999Z"
              fill="currentColor"
            />
          </g>
        </svg>
      </div>
      <div ref={contentRef} className={`bf-faq-answer ${isActive ? "open" : ""}`}>
        <div className="bf-faq-answer-wrapper">{parse(answer)}</div>
      </div>
    </div>
  )
}
