import "./MediaBg.css"

export interface IMediaProps {
  videoPortraitUrl?: string
  videoLandscapeUrl?: string
  imagePortraitUrl?: string
  imageLandscapeUrl?: string
}

const MediaBg = ({
  videoPortraitUrl,
  videoLandscapeUrl,
  imagePortraitUrl,
  imageLandscapeUrl
}: IMediaProps) => {
  return (
    <>
      {videoLandscapeUrl && videoPortraitUrl ? (
        <video className="bf-bg" autoPlay loop muted playsInline>
          {videoPortraitUrl && (
            <source src={videoPortraitUrl} type="video/mp4" media="(max-width: 600px)" />
          )}
          {videoLandscapeUrl && (
            <source src={videoLandscapeUrl} type="video/mp4" media="(min-width: 601px)" />
          )}
        </video>
      ) : null}

      {imageLandscapeUrl || imagePortraitUrl ? (
        <>
          {imagePortraitUrl && (
            <img src={imagePortraitUrl} alt="background" className="bf-bg bg-img bg-img-portrait" />
          )}

          {imageLandscapeUrl && (
            <img
              src={imageLandscapeUrl}
              alt="background"
              className="bf-bg bg-img bg-img-landscape"
            />
          )}
        </>
      ) : null}
    </>
  )
}

export default MediaBg
