import Slider from "react-slick"

import TeamCard from "./teamCard/TeamCard"

import Image from "../../../../assets/img/bf-landing/team.png"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./Team.css"
import { useEffect, useState } from "react"
import { Member } from "../../../../models/TeamMember"
import { getTeamMembersService } from "../../../../services/teamService"
import { ROLE_PSYCHOLOGIST, ROLE_TRAINER } from "../../../../utils/constants"

export interface ITeamProps {
  subtitle?: string
  rolesToShow?: "all" | "nutritionists" | "psychologists"
}

export default function Team({ subtitle, rolesToShow = "all" }: ITeamProps) {
  const [team, setTeam] = useState<Member[]>([])

  useEffect(() => {
    async function fetchTeamMembers() {
      const members = await getTeamMembersService()
      if (rolesToShow === "all") {
        setTeam(members.filter(member => member.role === ROLE_TRAINER || ROLE_PSYCHOLOGIST))
      }
      if (rolesToShow === "nutritionists") {
        setTeam(members.filter(member => member.role === ROLE_TRAINER))
      }
      if (rolesToShow === "psychologists") {
        setTeam(members.filter(member => member.role === ROLE_PSYCHOLOGIST))
      }
      // setTeam(members.filter(member => member.role === ROLE_TRAINER || ROLE_PSYCHOLOGIST))
      // setPsychologists(members.filter(member => member.role === ROLE_PSYCHOLOGIST));
    }
    fetchTeamMembers()
  }, [])

  const settings = {
    centerMode: true,
    infinite: true,
    centerPadding: "143px",
    slidesToShow: 2,
    speed: 500,
    focusOnSelect: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          centerPadding: "40px",
          slidesToShow: 2
        }
      },
      {
        breakpoint: 800,
        settings: {
          centerPadding: "20px",
          slidesToShow: 2
        }
      },
      {
        breakpoint: 727,
        settings: {
          slidesToShow: 1,
          centerPadding: "80px"
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          centerPadding: "0"
        }
      }
    ]
  }

  const cards = [
    {
      memberName: "Lucía",
      role: "Nutricionista",
      description:
        "Lucía es graduada en Nutrición Humana y dietética por la Universidad de Granada. Además tiene un Máster de Especialista en Nutrición Clínica y Comunitaria, así como de especialización en salud digestiva y obesidad. Su motivación es mejorar la calidad de vida de la población en el ámbito de la alimentación y sus hábitos a través de la educación nutricional, permitiendo cambios a largo plazo.",
      imageUrl: Image,
      instagramUrl: "https://instagram.com/vikikateam"
    },
    {
      memberName: "Rebeca",
      role: "Psicóloga",
      description:
        "Rebeca es graduada en psicología por la Universidad de Granada. Además tiene un Máster de Especialista en Nutrición Clínica y Comunitaria, así como de especialización en salud digestiva y obesidad. Su motivación es mejorar la calidad de vida de la población en el ámbito de la alimentación y sus hábitos a través de la educación nutricional, permitiendo cambios a largo plazo.",
      imageUrl: Image,
      instagramUrl: "https://instagram.com/vikikateam"
    },
    {
      memberName: "Paula",
      role: "Nutricionista",
      description:
        "Lucía es graduada en Nutrición Humana y dietética por la Universidad de Granada. Además tiene un Máster de Especialista en Nutrición Clínica y Comunitaria, así como de especialización en salud digestiva y obesidad. Su motivación es mejorar la calidad de vida de la población en el ámbito de la alimentación y sus hábitos a través de la educación nutricional, permitiendo cambios a largo plazo.",
      imageUrl: Image,
      instagramUrl: "https://instagram.com/vikikateam"
    },
    {
      memberName: "María",
      role: "Psicóloga",
      description:
        "Rebeca es graduada en psicología por la Universidad de Granada. Además tiene un Máster de Especialista en Nutrición Clínica y Comunitaria, así como de especialización en salud digestiva y obesidad. Su motivación es mejorar la calidad de vida de la población en el ámbito de la alimentación y sus hábitos a través de la educación nutricional, permitiendo cambios a largo plazo.",
      imageUrl: Image,
      instagramUrl: "https://instagram.com/vikikateam"
    }
  ]

  return (
    <section className="bf-team">
      <div className="bf-team-wrapper">
        <h2 className="bf-team-title">Nuestro equipo</h2>
        <p className="bf-team-subtitle">
          {subtitle
            ? subtitle
            : "Conoce a nuestro equipo de profesionales referentes en el ámbito de la nutrición,entrenamiento y psicología"}
        </p>
        <div className="bf-team-slider">
          <Slider {...settings}>
            {team.map((card, index) => (
              <TeamCard
                key={index}
                memberName={card.name}
                role={card.position}
                description={card.description}
                imageUrl={card.image}
                instagramUrl={card.instagram_url}
              />
            ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}
