import Button from "../../atoms/button/Button"
import infoIconUrl from "../../../../../assets/img/info-circle.png"
import "./CardProduct.css"

export interface ICardProductProps {
  infoText?: string
  price: number
  linkTo: string
  onClickBuyButton: () => void
}

export default function CardProduct({
  infoText,
  price,
  linkTo,
  onClickBuyButton
}: ICardProductProps) {
  return (
    <div className="card-product">
      <div className="card-product-content">
        <div className="card-product-price-content">
          <div className="card-product-price-wrapper">
            <h2 className="card-product-price">{price}</h2>
            <p className="card-product-currency">€/mes</p>
          </div>
          <p className="card-product-tax">IVA incluido</p>
        </div>

        <div className="card-product-wrapper">
          {infoText ? <p className="card-product-description">{infoText}</p> : null}
          <div className="card-product-info-wrapper">
            <img src={infoIconUrl} alt="info" className="card-productinfo-icon" />
            <p className="card-product-info-text">
              Si tienes dudas de cómo funciona te lo explicamos <a href="#help">aquí</a>
            </p>
          </div>
        </div>

        <div className="card-product-wrapper">
          <Button ctaText="COMPRAR" onClick={onClickBuyButton} />
          <p className="card-product-info-text">
            Si tienes alguna duda sobre el producto{" "}
            <a href={linkTo} target="_blank" rel="noreferrer">
              mándanos un mensaje
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
