import { useEffect, useRef, useState } from "react"
import parse from "html-react-parser"

import "./TeamCard.css"
import instagramLogoUrl from "../../../../../assets/img/instagram.svg"

interface TeamCardProps {
  memberName: string
  role: string
  description: string
  imageUrl: string
  instagramUrl?: string
}

export default function TeamCard({
  memberName,
  role,
  description,
  imageUrl,
  instagramUrl
}: TeamCardProps) {
  const [isActive, showMemberInfo] = useState(false)
  const cardRef = useRef<HTMLDivElement>(null) // Referencia para el contenedor de la tarjeta

  // useEffect para manejar el clic fuera de la tarjeta
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        showMemberInfo(false) // Cierra el contenido si se hace clic fuera
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  return (
    <div
      ref={cardRef}
      className={`bf-team-card geopol-team-card ${isActive ? "overlay-active" : ""}`}
    >
      {instagramUrl && (
        <a
          href={instagramUrl}
          target="_blank"
          rel="noreferrer"
          className="bf-team-card-instagram-logo-wrapper"
        >
          <img src={instagramLogoUrl} alt="Instagram" className="bf-team-card-instagram-logo" />
        </a>
      )}
      <img src={imageUrl} alt={memberName} className="bf-team-card-photo" loading="lazy" />
      <div className={`bf-team-card-content`}>
        <h3 className="bf-team-card-name">{memberName}</h3>
        <p className="bf-team-card-role">{role}</p>
        <div className="bf-team-card-description">{parse(description)}</div>
        <button onClick={() => showMemberInfo(!isActive)} className="bf-team-card-button">
          {isActive ? `Ver menos de ${memberName}` : `Saber más de ${memberName}`}
        </button>
      </div>
    </div>
  )
}
