import Hero from "../../shared/landing/hero/Hero"
import Info from "../../shared/landing/info/Info"
import Promotions from "../../shared/landing/promotions/Promotions"
import Team from "../../shared/landing/team/Team"
import Testimonial from "../../shared/landing/testimonial/Testimonial"
import Faq from "../../shared/landing/faq/Faq"
import { useEffect, useState } from "react"
import { productDetailService } from "../../../services/productDetailService"
import { ProductDetailData } from "../../../models/ProductDetailData"

import landscapeVideoUrl from "../../../assets/blackfriday/vikika-team-landscape.mp4"
import portraitVideoUrl from "../../../assets/blackfriday/vikika-team-portrait.mp4"

const BlackFriday = () => {
  const [dietProducts, setDietProducts] = useState<ProductDetailData[]>([])

  const [psychologyProducts, setPsychologyProducts] = useState<ProductDetailData[]>([])

  useEffect(() => {
    const fetchData = async () => {
      const dietProducts = await productDetailService("diet")
      const psychologyProducts = await productDetailService("psychology")
      setDietProducts(dietProducts)
      setPsychologyProducts(psychologyProducts)
    }
    fetchData()
  }, [])

  const scrollToPromotions = () => {
    const promotionsElement = document.getElementById("promotions")
    if (promotionsElement) {
      promotionsElement.scrollIntoView({ behavior: "smooth" })
    }
  }
  return (
    <div className="bf-landing">
      <Hero
        scrollToPromotions={scrollToPromotions}
        marqueeText="¡OFERTAS BLACK FRIDAY EN TODOS NUESTROS PLANES!"
        videoLandscapeUrl={landscapeVideoUrl}
        videoPortraitUrl={portraitVideoUrl}
      />
      <Info />
      <div id="promotions">
        <Promotions dietProducts={dietProducts} psychologyProducts={psychologyProducts} />
      </div>
      <Team />
      <Faq />
      <Testimonial />
    </div>
  )
}

export default BlackFriday
