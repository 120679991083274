import "./Hero.css"
import Marquee, { IMarqueeProps } from "./marquee/Marquee"
import MediaBg, { IMediaProps } from "./media-background/MediaBg"
import Logo from "./logo/Logo"
import Countdown from "./countdown/Countdown"
import Button, { IButtonProps } from "../atoms/button/Button"

export interface IHeroProps {
  logoUrl?: string
  title?: string
  subtitle?: string
  scrollToPromotions: () => void
  isInternalLanding?: boolean
  marqueeText?: IMarqueeProps["marqueeText"]
  ctaText?: IButtonProps["ctaText"]
  videoPortraitUrl?: IMediaProps["videoPortraitUrl"]
  videoLandscapeUrl?: IMediaProps["videoLandscapeUrl"]
  imagePortraitUrl?: IMediaProps["imagePortraitUrl"]
  imageLandscapeUrl?: IMediaProps["imageLandscapeUrl"]
}

export default function Hero({
  logoUrl,
  title,
  subtitle,
  scrollToPromotions,
  isInternalLanding = false,
  marqueeText,
  ctaText,
  videoPortraitUrl,
  videoLandscapeUrl,
  imagePortraitUrl,
  imageLandscapeUrl
}: IHeroProps) {
  return (
    <div className={`bf-hero-container ${!marqueeText ? "brand-landing" : ""}`}>
      <MediaBg
        videoPortraitUrl={videoPortraitUrl}
        videoLandscapeUrl={videoLandscapeUrl}
        imagePortraitUrl={imagePortraitUrl}
        imageLandscapeUrl={imageLandscapeUrl}
      />

      {marqueeText ? (
        <div className="bf-hero-marquee-wrapper">
          <Marquee marqueeText={marqueeText} />
        </div>
      ) : null}

      {!isInternalLanding ? (
        <div className="bf-hero-logo-container">
          <Logo />
        </div>
      ) : null}

      <div className="bf-hero-content">
        {Countdown ? <Countdown /> : null}

        <div className="bf-hero-title-wrapper">
          {logoUrl ? <img src={logoUrl} alt="Logo" className="brand-logo" /> : null}
          <h1 className="bf-hero-title">
            <span dangerouslySetInnerHTML={{ __html: title || "Ofertas <br /> Black Friday" }} />
          </h1>
          <h2 className="bf-hero-title-subtitle">{subtitle ? subtitle : "VIKIKA TEAM"}</h2>
        </div>

        <Button onClick={scrollToPromotions} ctaText={ctaText} />
      </div>
    </div>
  )
}
