import React, { useState } from "react"
import AccordionItem from "../atoms/accordion-item/AccordionItem"
import "./Faq.css"
import Button from "../atoms/button/Button"
import { useNavigate } from "react-router-dom"
import { defaultFaqsData } from "./defaultFaqsData"

export interface IFaqProps {
  dataToDisplay?: any[]
}

export default function Faq({ dataToDisplay }: IFaqProps) {
  const navigate = useNavigate()
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const moreFaqs = () => {
    navigate(`/faq`)
  }

  const faqsData = dataToDisplay ? dataToDisplay : defaultFaqsData

  return (
    <div className="bf-faq">
      <div className="bf-faq-container">
        <div className="bf-faq-title-wrapper">
          <h2 className="bf-faq-title">Preguntas frecuentes</h2>
          <p className="bf-faq-title-subtitle">
            Todas las preguntas y respuestas sobre Vikika Team
          </p>
        </div>

        <div className="bf-faq-wrapper">
          {faqsData.map((faq, index) => (
            <AccordionItem
              key={index}
              question={faq.question}
              answer={faq.answer}
              isActive={activeIndex === index}
              onClick={() => setActiveIndex(activeIndex === index ? null : index)}
            />
          ))}
        </div>
        <div className="bf-faq-button-wrapper">
          <Button ctaText="¿QUIERES SABER MÁS?" onClick={() => moreFaqs()} variant="outline" />
        </div>
      </div>
    </div>
  )
}
