import { Ilustration1 } from "./Ilustration1"
import { Ilustration2 } from "./Ilustration2"
import { Ilustration3 } from "./Ilustration3"
import { Ilustration4 } from "./Ilustration4"

export const processData = [
  {
    icon: <Ilustration1 />,
    number: "1",
    title: "Compra el plan"
  },
  {
    icon: <Ilustration2 />,
    number: "2",
    title: "Rellena el cuestionario"
  },
  {
    icon: <Ilustration3 />,
    number: "3",
    title: "Recibe tu guía"
  },
  {
    icon: <Ilustration4 />,
    number: "4",
    title: "Realiza una videollamada con tu nutricionista"
  }
]
