import "./Button.css"

export interface IButtonProps {
  variant?: "solid" | "outline"
  ctaText?: string
  disabled?: boolean
  onClick?: () => void
}

const Button = ({ ctaText, onClick, disabled = false, variant = "solid" }: IButtonProps) => {
  return (
    <button onClick={onClick} disabled={disabled} className={`bf-cta bf-cta-${variant}`}>
      {ctaText ? ctaText : "¡DESCUBRE LAS OFERTAS!"}
    </button>
  )
}

export default Button
