import { ReactNode } from "react"
import "./Process.css"

export interface IProcessProps {
  data: { icon: ReactNode; title: string; description?: string }[]
}

export default function Process({ data }: IProcessProps) {
  return (
    <div className="process-container">
      {data.map((item, index) => (
        <div key={index} className="process-item">
          {item.icon}
          <div className="process-item-title-wrapper">
            <span className="process-item-number">{index + 1}</span>
            <div className="process-item-text">
              <h3 className="process-item-title">{item.title}</h3>
              {item.description ? (
                <p className="process-item-description">{item.description}</p>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
