import Accordion from "./accordion-product/Accordion"
import CardProduct, { ICardProductProps } from "./card-product/CardProduct"
import "./GeopolProducts.css"
import Process, { IProcessProps } from "./process-illustrations/Process"

export interface IGeopolProductsProps {
  title: string
  subtitle?: string
  price: ICardProductProps["price"]
  processData: IProcessProps["data"]
  linkTo: ICardProductProps["linkTo"]
  onClickBuyButton: () => void
}

export default function GeopolProducts({
  title,
  subtitle,
  price,
  processData,
  linkTo,
  onClickBuyButton
}: IGeopolProductsProps) {
  return (
    <div className="geopol-products" id="GeopolProducts">
      <div className="geopol-products-content">
        <div className="geopol-products-title-wrapper">
          <h1 className="geopol-products-title">{title}</h1>
          <p className="geopol-products-subtitle">{subtitle}</p>
        </div>

        <div className="geopol-products-pricing">
          <CardProduct price={price} linkTo={linkTo} onClickBuyButton={onClickBuyButton} />
          <div className="geopol-products-details">
            <Process data={processData} />
            <Accordion />
          </div>
        </div>
      </div>
    </div>
  )
}
