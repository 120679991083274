import { useState, useEffect } from "react"
import NutritionCheckbox from "../checkbox/NutritionCheckbox"
import Button from "../../../atoms/button/Button"
import RadioOption from "../radio/RadioOption"
import isBlackFriday from "../../../../../views/blackfriday/utils/isBlackFriday"
import { useNavigate } from "react-router-dom"

import "./Promo.css"
import infoIconUrl from "../../../../../../assets/img/info-circle.png"
import { ProductDetailData, RadioCheckBoxOption } from "../../../../../../models/ProductDetailData"

interface PromoProps {
  product: ProductDetailData
  inCouple: boolean
}

export default function NutritionPromo({ product, inCouple }: PromoProps) {
  const navigate = useNavigate()

  /**
   * Variables needed for cart
   */
  const [productCategory, setProductCategory] = useState<String>("diet")
  const [savedRadioProduct, setSavedRadioProduct] = useState<RadioCheckBoxOption>()
  const [productPrice, setProductPrice] = useState<number>(product.base_price)
  const [productSubscriptionPrice, setProductSubscriptionPrice] = useState<number>(
    product.subscription_price
  )
  const [selectedProductId, setSelectedProductId] = useState<string>(product.idBasic)

  /**
   * Variables not needed for cart
   */
  const [isNutritionChecked, setIsNutritionChecked] = useState(false)
  const [price, setPrice] = useState<any>(0)
  const [prevPrice, setPrevPrice] = useState<number>(productPrice + productSubscriptionPrice)
  const [selectedTrainingOption, setSelectedTrainingOption] = useState<string | null>(null)
  const [showPromoButton] = useState<boolean>(isBlackFriday(new Date()))

  /**
   * Used to update the visual previous price
   */
  useEffect(() => {
    setPrevPrice(productPrice + productSubscriptionPrice)
  }, [productPrice, productSubscriptionPrice])

  /**
   * Used to update the visual current price
   */
  useEffect(() => {
    setPrice(!inCouple ? prevPrice - prevPrice * 0.15 : prevPrice - prevPrice * 0.1)
  }, [prevPrice])

  /**
   * Used to update productId and prices when couple toggle changes
   */
  useEffect(() => {
    setProductPrice(
      !inCouple
        ? product.base_price
        : product.checkbox_option.new_price_reduced_composed ||
            product.checkbox_option.new_price_composed
    )
    setProductSubscriptionPrice(
      !inCouple ? product.subscription_price : product.checkbox_option.feeComposed || 0
    )
    setSelectedProductId(!inCouple ? product.idBasic : product.idCouple)
  }, [inCouple])

  /**
   * Reset variables when couple toggle changes
   */
  useEffect(() => {
    setIsNutritionChecked(false)
    setSelectedTrainingOption(null)
  }, [inCouple])

  const handleButtonClick = () => {
    localStorage.removeItem("payment_intent")
    localStorage.removeItem("client_secret")
    localStorage.removeItem("facturationData")
    localStorage.setItem(
      "savedSelectedProduct",
      JSON.stringify({
        value: savedRadioProduct,
        productId: selectedProductId,
        variantId: null,
        productPrice: productPrice,
        name: product.name,
        img: product.img,
        alt: product.alt,
        productCategory: productCategory,
        isEnPareja: inCouple,
        productSubscription: product.product_subscription,
        productSubscriptionPrice: productSubscriptionPrice
      })
    )
    const discount = inCouple ? "CM24-10" : "CM24-15"
    navigate(`/pago?coupon=${discount}`)
  }

  const handleCheckChange = (checked: boolean) => {
    setIsNutritionChecked(checked)
    if (checked) {
      const firstOption = product.radio_options[0].value
      setSelectedTrainingOption(firstOption)
      setProductPrice(
        !inCouple
          ? product.radio_options[0].new_price_reduced_lonelly
          : product.radio_options[0].new_price_reduced_composed
      )
      setProductSubscriptionPrice(
        !inCouple ? product.radio_options[0].feeLonely : product.radio_options[0].feeComposed
      )
      setSelectedProductId(
        !inCouple ? product.radio_options[0].idLonelly : product.radio_options[0].idComposed
      )
    } else {
      setSelectedTrainingOption(null)
      setProductPrice(
        !inCouple
          ? product.base_price
          : product.checkbox_option.new_price_reduced_composed ||
              product.checkbox_option.new_price_composed
      )
      setProductSubscriptionPrice(
        !inCouple ? product.subscription_price : product.checkbox_option.feeComposed || 0
      )
    }
  }

  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: RadioCheckBoxOption
  ) => {
    setSelectedTrainingOption(event.target.value)
    setSavedRadioProduct(option)
    setProductPrice(
      !inCouple ? option.new_price_reduced_lonelly : option.new_price_reduced_composed
    )
    setProductSubscriptionPrice(!inCouple ? option.feeLonely : option.feeComposed)
    setSelectedProductId(!inCouple ? option.idLonelly : option.idComposed)
  }

  const getRadioOptions = () => {
    const options = product.radio_options

    return options.map((option, index) =>
      inCouple && index === 2 ? null : (
        <RadioOption
          key={option.value}
          id={`nutrition-promo-${option.value}`}
          label={option.option_name}
          name={`nutrition-couple-${inCouple.toString().toLowerCase()}`}
          value={option.value}
          checked={selectedTrainingOption === option.value}
          onChange={event => handleRadioChange(event, option)}
        />
      )
    )
  }

  return (
    <div className="bf-promo-wrapper">
      {/* Price section */}
      <div className="bf-promo-price-container">
        <div className="bf-promo-price-wrapper">
          <div className="bf-promo-price">
            <h2 className="bf-promo-price-number">
              {price % 1 === 0
                ? price
                : price.toLocaleString("es-ES", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
            </h2>
            <div>
              <h3 className="bf-promo-price-promo">PROMO</h3>
              <p className="bf-promo-price-simbol">€</p>
            </div>
          </div>
          <p className="bf-promo-prev-price">
            Antes:{" "}
            {prevPrice % 1 === 0
              ? prevPrice
              : prevPrice.toLocaleString("es-ES", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}
            €
          </p>
        </div>
      </div>

      {/* Info section */}
      <div className="bf-promo-info-wrapper">
        <p className="bf-promo-info-text">
          Nuestro equipo de especialistas adaptará el plan de forma personalizada a tus necesidades,
          gustos y objetivos.
        </p>
        <div className="bf-promo-plan-selector">
          <NutritionCheckbox
            label="Añade un entrenamiento a tu plan"
            checked={isNutritionChecked}
            onChange={handleCheckChange}
          />
          {isNutritionChecked && <div className="bf-promo-radio-wrapper">{getRadioOptions()}</div>}
        </div>

        <div className="bf-promo-info-extra">
          <img src={infoIconUrl} alt="info" className="bf-promo-info-icon" />
          <p className="bf-promo-info-anchor">
            Si tienes dudas de cómo funciona te lo explicamos{" "}
            <a href="https://vikika.es/productos/dieta" target="_blank">
              aquí
            </a>
          </p>
        </div>
      </div>
      {/* Button section */}
      {showPromoButton && (
        <div>
          <Button ctaText="COMPRAR" onClick={handleButtonClick} />
        </div>
      )}
    </div>
  )
}
