import Hero from "../../shared/landing/hero/Hero"
import GeopolProducts from "../../shared/landing/products/GeopolProducts"
import Team from "../../shared/landing/team/Team"
import Faq from "../../shared/landing/faq/Faq"
import Questions from "../../shared/questions/Questions"
import { geopolFaqsData } from "./faqs/geopolFaqsData"

import { processData } from "./ilustrations/geopolProcessData"

import LogoGeopol from "../../../assets/img/logo-vikika-team+geopol.png"
import portraitImageUrl from "../../../assets/img/geopol-mobile.png"
import landscapeImageUrl from "../../../assets/img/geopol-desktop.png"

import "./geopol.css"
import { useNavigate } from "react-router-dom"

const Geopol = () => {
  const navigate = useNavigate()

  const scrollToPromotions = () => {
    const promotionsElement = document.getElementById("GeopolProducts")
    if (promotionsElement) {
      promotionsElement.scrollIntoView({ behavior: "smooth" })
    }
  }

  const handleClickBuyButton = () => {
    navigate(
      "/pago?product=ewogICJ2YWx1ZSI6IG51bGwsCiAgInByb2R1Y3RJZCI6ICIzODQ0MTAiLAogICJ2YXJpYW50SWQiOiAiIiwKICAicHJvZHVjdFByaWNlIjogNDUsCiAgIm5hbWUiOiAiUGxhbiBHZW9wb2wiLAogICJpbWciOiAiaHR0cHM6Ly93d3cudmlraWthLmVzL3dwLWNvbnRlbnQvdXBsb2Fkcy8yMDE4LzExL0RpZXRhLnBuZyIsCiAgImFsdCI6ICJkaWV0YSIsCiAgInByb2R1Y3RDYXRlZ29yeSI6ICJkaWV0IiwKICAiaXNFblBhcmVqYSI6IGZhbHNlLAogICJwcm9kdWN0U3Vic2NyaXB0aW9uIjogZmFsc2UsCiAgInByb2R1Y3RTdWJzY3JpcHRpb25QcmljZSI6IDAKfQ=="
    )
  }

  return (
    <div className="geopol-landing">
      <Hero
        isInternalLanding
        logoUrl={LogoGeopol}
        scrollToPromotions={scrollToPromotions}
        title="Tu guía nutricional"
        subtitle="Educación nutricional para conseguir tus objetivos"
        ctaText="Descrubre más"
        imagePortraitUrl={portraitImageUrl}
        imageLandscapeUrl={landscapeImageUrl}
      />
      <GeopolProducts
        title="Guía nutricional GeoPol"
        subtitle="Aprende, a través de una guía de educación nutricional elaborada por los nutricionistas de Vikika Team, a mejorar tus hábitos de alimentación, adaptándote de forma eficaz a la fase de la oposición en la que te encuentras."
        price={45}
        processData={processData}
        linkTo="https://vikika.es/contacto"
        onClickBuyButton={handleClickBuyButton}
      />
      <Team subtitle="Profesionales referentes en nutrición" rolesToShow="nutritionists" />
      <Faq dataToDisplay={geopolFaqsData} />
      <Questions />
    </div>
  )
}

export default Geopol
