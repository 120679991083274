export const AccordionData = [
  {
    question: "¿En qué consiste la guía nutricional GeoPol?",
    answer:
      "<p>La guía nutricional GeoPol & Vikika Team es una guía de educación nutricional que nuestro equipo de nutricionistas preparará para ti, según los objetivos que tengas respecto a la fase de la oposición en la que te encuentres.</p><br><p>El nutricionista te indicará, en función del tiempo del que dispongas hasta tus pruebas físicas, las mejores recomendaciones respecto a tus hábitos de alimentación, con el fin de optimizar y mejorar tu rendimiento.</p>"
  },
  {
    question: "¿Qué incluye?",
    answer:
      "Este servicio incluye una guía de educación nutricional, que el nutricionista elaborará basándose en los datos que nos proporciones a través del cuestionario inicial. En ella, te explicará cómo debes alimentarte con el fin de alcanzar tus objetivos. La guía incluirá información relacionada con la frecuencia de consumo de alimentos, la elección correcta de alimentos, ejemplos de menús con los macronutrientes adecuados a tu objetivo, suplementación en caso de ser necesaria y consejos saludables sobre tus hábitos diarios."
  },
  {
    question: "¿Cómo es el proceso?",
    answer:
      "<ol><li>Compra tu plan. Una vez realices la compra del producto, accederás a tu panel personal en Vikika Team y podrás rellenar el cuestionario, el cual será necesario para que el nutricionista pueda preparar tu guía de educación nutricional.</li><li>Rellena y envía tu cuestionario. Cuando envíes el cuestionario, en un plazo de 48-72 h te asignaremos un nutricionista y recibirás la guía de educación nutricional enfocada a tus objetivos.</li><li>Recibe tu guía. Tras recibir tu guía de educación nutricional, tendrás 5 días disponibles para agendar una videollamada con tu nutricionista.</li><li>Videollamada con tu nutricionista. En la videollamada, que tendrá una duración de 15 minutos aproximadamente, tu nutricionista te explicará todos los detalles necesarios sobre la guía.</li></ol>"
  }
]
